export const environment = {
  production: true,
  api: {
    core: {
      uri: 'https://ats1.call-matrix.com:9443',
      path: '/api/v1',
    },
  },
  softphone: {
    scheme: 'wss',
    server: 'ats1.call-matrix.com',
    port: '6061',
    path: '/ws',
    sipDomain: 'tenant_',
  },
  stripe: {
    key: 'pk_test_51PQT0XCwTE1A5l0hABUoyPInGqBKps5NtweH4SRsChBYKPwBbrK8GKqBxNVHP6Fc6ZJiXFaLmFywHhYTjCJMaqVu008bcG7xxl',
  },
  recaptcha: {
    key: '6LdAYI4pAAAAAFQQLBVJzDlOWWZgLsqpu5UrLitX'
  }
};
