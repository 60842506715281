import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { StoreService } from '@app/services/store/store.service';
import Stepper from 'bs-stepper';
// @ts-ignore
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ConfirmedValidator } from './confirmed.validator';

// @ts-ignore
import Countries from '../../../../assets/data/countries.json';
import States from '../../../../assets/data/states.json';

import { StripeService, StripePaymentElementComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { LayoutService } from '@app/services/app.layout.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { businessIndustries, signupDescription, signupPrices, signupSelectedPrice } from '@app/models/signups';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  @ViewChild(StripePaymentElementComponent) paymentElement: StripePaymentElementComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#7d8490',
        color: '#7d8490',
        '::placeholder': {
          color: '#7d8490',
        },
      },
    },
  };
  elementsOptions: StripeElementsOptions;
  scheme = 'dark';
  subscriptions$: Subscription[] = [];
  isCardValid: boolean = true;
  emailPattern: RegExp = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  usernamePattern: RegExp = /^[a-z0-9\._-]{5,255}$/;
  name = 'Registration';
  private stepper: Stepper;
  private stepperEl: HTMLElement;
  
  description = signupDescription;
  businessIndustries = businessIndustries;
  prices = signupPrices;
  selectedPrice = signupSelectedPrice;

  balance: string = '250';

  products = [{ label: '() - 0', value: { id: 0, name: '', description: '-', recur: '', price: 0 } }];
  selectedProduct: any;
  provinces: any[] = [];
  monthlyPlan;
  yearlyPlan;
  defaultPlanName = '';
  uniqueProducts = [];
  selectedPlan;

  emailForm: UntypedFormGroup;
  codeForm: UntypedFormGroup;
  profileForm: UntypedFormGroup;
  checkoutForm: UntypedFormGroup;
  submitted = false;
  countries = Countries.map(country => {
    country['label'] = country.name;
    country.code = country.code.toLowerCase();
    return country;
  });
  selectedCountry;
  notTest = true;
  stepComplete = 0;
  resTokenId = null;

  loading: boolean = false;
  stepIndex: number = 0;

  stepItems: MenuItem[];

  plan_sel = '-';
  isPaymentSucceed = false;

  get isInvalid() {
    return (this.emailForm.invalid && this.stepIndex === 0) || (this.codeForm.invalid && this.stepIndex === 1) || (this.profileForm.invalid && this.stepIndex === 2) || (this.checkoutForm.invalid && this.stepIndex === 3 ||!this.isCardValid );
  }

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private api: ApiService,
    private store: StoreService,
    public messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    private stripeService: StripeService,
    private layoutService: LayoutService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit() {
    // this.scheme = this.layoutService.config.colorScheme;
    // this.subscriptions$.push(this.layoutService.configUpdate$.subscribe(config => {
    //   this.scheme = config.colorScheme;
    // }));

    this.route.queryParams.subscribe(param => {
      if (param?.plan) {
        this.defaultPlanName = param.plan.toLowerCase();
      }
    });

    this.stepItems = [
      // { label: 'Verify Email', routerLink: 'verify-email' },
      // { label: 'Confirm Email', routerLink: 'confirm-email' },
      // { label: 'Profile', routerLink: 'profile' },
      // { label: 'Checkout', routerLink: 'checkout' },
      { label: 'Verify Email' },
      { label: 'Confirm Email' },
      { label: 'Profile' },
      { label: 'Checkout' },
    ];

    this.emailForm = this.formBuilder.group({
      email: [this.defValue.email, [Validators.required, Validators.pattern(this.emailPattern)]],
      username: [this.defValue.username, [Validators.required, Validators.pattern(this.usernamePattern)]],
    });

    this.codeForm = this.formBuilder.group({
      code: [this.defValue.code, Validators.required],
    });

    this.profileForm = this.formBuilder.group(
      {
        firstName: [this.defValue.firstName, Validators.required],
        lastName: [this.defValue.lastName, Validators.required],
        password: [this.defValue.password, Validators.required],
        confirm_password: [this.defValue.confirm_password, Validators.required],
        business_name: [this.defValue.business_name, Validators.required],
        business_number: [this.defValue.business_number],
        business_website: [this.defValue.business_website],
        business_address: this.formBuilder.group({
          street_address: [this.defValue.business_address.street_address],
          city: [this.defValue.business_address.city],
          province: [this.defValue.business_address.province],
          country: [this.defValue.business_address.country],
          zip_code: [this.defValue.business_address.zip_code],
        }),
        business_industry: [this.defValue.business_industry],
      },
      {
        validator: ConfirmedValidator('password', 'confirm_password'),
      }
    );

    this.checkoutForm = this.formBuilder.group({
      card_name: [this.defValue.card_name, Validators.required],
      amount: [(parseInt(this.balance) * 100).toString(), Validators.required],
      // zip_code: [this.defValue.zip_code, Validators.required],
      // country: [this.defValue.country, Validators.required],
    });

    this.getProducts();
  }

  canGoNext(toIndex, ignoreStep = false) {
    if (toIndex === 0) {
      this.submitted = true;
    }

    if (toIndex === 1) {
      this.submitted = true;
      if (this.stepComplete < 1 && !ignoreStep) return false;
      if (this.emailForm.invalid) return false;
      let username = this.getEmailForm.username.value;
      if (/^(?=.{4,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/.test(username)) {
      } else {
        this.showWarning('Please enter valid username');
        return false;
      }
    }

    if (toIndex === 2) {
      this.submitted = true;
      if (this.stepComplete < 2 && !ignoreStep) return false;
      if (this.codeForm.invalid) return false;
      if (this.emailForm.invalid) return false;
    }

    if (toIndex === 3) {
      this.isCardValid = false;
      this.submitted = true;
      if (this.profileForm.invalid) return false;
      if (this.codeForm.invalid) return false;
      if (this.emailForm.invalid) return false;
    }

    if (toIndex === 4) {
      this.submitted = true;
      if (this.checkoutForm.invalid) return false;
      if (this.profileForm.invalid) return false;
      if (this.codeForm.invalid) return false;
      if (this.emailForm.invalid) return false;
    }

    return true;
  }

  async next() {
    try {
      if (!this.canGoNext(this.stepIndex + 1, true)) return;

      // Request For Email
      if (this.stepIndex === 0) {
        this.subscriptions$.push(this.recaptchaV3Service.execute('importantAction').subscribe({
          next: (token) => {
            if (!token?.length) {
              this.showWarning(
                'Please try again.'
              )
            }
            else {
              this.request_email().then(() => {}, error => {
                this.stepIndex = 0;
              });
              this.submitted = false;
              this.stepIndex++;
            }
          }
        }))
      }

      if (this.stepIndex === 1) {
        this.subscriptions$.push(this.recaptchaV3Service.execute('importantAction').subscribe({
          next: (token) => {
            if (!token?.length) {
              this.showWarning(
                'Please try again.'
              )
            }
            else {
              this.verify_email().then(() => (this.submitted = false, this.stepIndex++), error => {
                this.stepIndex = 1;
              });
            }
          }
        }))
      }

      // Prepare to Checkout
      if (this.stepIndex === 2) {

        this.subscriptions$.push(this.recaptchaV3Service.execute('importantAction').subscribe({
          next: (token) => {
            if (!token?.length) {
              this.showWarning(
                'Please try again.'
              )
            }
            // Retrieve Client Secret from Server
            else {
              this.api.getPaymentIntent(String(this.balance), null).subscribe(
                res => {
                  this.elementsOptions = {
                    locale: 'en',
                    appearance: {
                      theme: this.scheme == 'dark' ? 'night' : 'stripe',
                    },
                    clientSecret: res.client_secret
                  };
                  this.submitted = false;
                  this.stepIndex++;
                },
                error => {
                  this.showWarning(error?.message ? error.message : 'Server comunication error, please try later!');
                }
              );
            }
          }
        }))
      }
      // this.stepper.next();
    } catch (e) {}
  }

  // convenience getter for easy access to form fields
  get defValue() {
    let ranUser = new Date().getTime() + '@ecm.ecm';
    return {
      username: this.notTest ? '' : '',
      email: this.notTest ? '' : ranUser,
      code: this.notTest ? '' : '',
      firstName: this.notTest ? '' : 'test',
      lastName: this.notTest ? '' : 'test',
      password: this.notTest ? '' : 'Admin1234?',
      confirm_password: this.notTest ? '' : 'Admin1234?',

      business_name: this.notTest ? '' : 'test',
      business_number: this.notTest ? '' : '100',
      business_website: this.notTest ? '' : 'test.ecm.ecm',
      business_address: {
        street_address : this.notTest ? '' : 'st test',
        city: this.notTest ? '' : 'IT',
        country: this.notTest ? '' : 'IT',
        province: this.notTest ? '' : 'IT',
        zip_code: this.notTest ? '' : '00100',
      },
      business_industry: this.notTest ? '' : 'Consumer',

      card_name: this.notTest ? '' : 'test card',
      card_number: this.notTest ? '' : '4242 4242 4242 4242',
      zip_code: this.notTest ? '' : '00100',
      country: this.notTest ? '' : 'IT',
      exp_date: this.notTest ? '' : '02/25',
      cvv: this.notTest ? '' : '123',
    };
  }

  get getEmailForm() {
    return this.emailForm.controls;
  }
  get getCodeForm() {
    return this.codeForm.controls;
  }
  get getProfileForm() {
    return this.profileForm.controls;
  }
  get getCheckoutForm() {
    return this.checkoutForm.controls;
  }

  get getRegisterObj() {
    let email = this.getEmailForm.email.value;
    let username = this.getEmailForm.username.value;
    let code = this.getCodeForm.code.value;
    let profile = this.getProfileForm;
    let checkout = this.getCheckoutForm;
    let plan = this.selectedPlan ? this.selectedPlan.value.id : null;
    let resTokenId = this.resTokenId;

    let obj = {
      email: email,
      code: code,
      username: username,
      password: profile.password.value,
      firstName: profile.firstName.value,
      lastName: profile.lastName.value,
      Customer: {
        name: profile.business_name.value,
        // address: profile.business_address.value,
        phone: profile.business_number.value,
        website: profile.business_website.value,
        industry: profile.business_industry.value,
      },
      BusinessAddress: {
        street_address: profile.business_address.value.street_address,
        city: profile.business_address.value.city,
        province: profile.business_address.value?.province?.name,
        zip_code: profile.business_address.value.zip_code,
        country: profile.business_address.value.country.name,
      },
      Service: {
        planId: plan,
        starting_balance: this.balance,
      },
      Payment: {
        cardName: checkout.card_name.value,
        cardNo: '1111111111111111', //checkout.card_number.value,
        cardExpDate: '11/11', //checkout.exp_date.value,
        cardCvv: '111', //checkout.cvv.value,
        // "zipCode": checkout.zip_code.value,
        // "country": checkout.country.value,
        token: resTokenId,
      },
    };

    return obj;
  }

  onBalanceChange(event) {
    this.balance = this.selectedPrice.value.toString();

    this.checkoutForm = this.formBuilder.group({
      card_name: [this.defValue.card_name, Validators.required],
      // amount: [(selected_balance * 100).toString(), Validators.required]
      // zip_code: [this.defValue.zip_code, Validators.required],
      // country: [this.defValue.country, Validators.required],
    });
  }

  getProducts() {
    this.loading = true;
    this.api.getProductsReg().subscribe(
      res => {
        this.loading = false;
        if (res) {
          let products = [];
          res.forEach(product => {
            if (product) {
              
              const row = {
                label: product.description + ' - ' + product.currency + ' ' + product.price,
                value: {
                  id: product.id,
                  name: product.Product.name,
                  price: product.price,
                  recur: product.recur,
                  currency: product.currency,
                  description: product.Product.description,
                },
              };

              products.push(row);
            }
          });

          this.products = [...products];

          this.uniqueProducts = [...new Set(this.products.map(item => item.value.name))];
          let selectedProduct;

          if (this.defaultPlanName?.length) {
            selectedProduct = this.products.find(x =>{
               if(x.value.name.toLowerCase().includes(this.defaultPlanName) || x.value.name.toLowerCase().includes(this.defaultPlanName === 'commercial' ? 'service' : this.defaultPlanName)){
                return x;
               }
              });
          } else {
            selectedProduct = products.length ? products[0] : null;
          }
          this.selectedPlan = selectedProduct;
          this.selectedProduct = selectedProduct?.value?.name;

          this.getSelectedProduct();
        } else {
        }
      },
      error => {
        this.loading = false;
      }
    );
  }

  getSelectedProduct() {
    if (!this.products) return;
    this.monthlyPlan = this.products.find(x => x.value.name === this.selectedProduct && x.value.recur.toLowerCase().includes("mon"));
    this.yearlyPlan = this.products.find(x => x.value.name === this.selectedProduct && x.value.recur.toLowerCase().includes("year"));
    this.selectedPlan = this.monthlyPlan;
  }

  resendCode() {
    if (this.canGoNext(1)) {
      this.request_email().then(() => { }, error => {
        this.stepIndex = 0;
      });
      this.submitted = false;
    }
  }

  request_email() {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.api.request_email(this.getEmailForm.username.value, this.getEmailForm.email.value).subscribe(
        res => {
          this.loading = false;
          if (res.verification_code) {
            this.stepComplete = 1;
            this.showSuccess("We've just sent verification code to you. Please check your inbox");
            resolve(res);
          } else {
            this.showWarning(res.message ? res.message : 'Failed to send email');
            reject();
          }
        },
        error => {
          this.loading = false;
          reject(error);
        },
        () => {
          this.loading = false;
        }
      );
    });
  }

  async verify_email() {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.api.verify_email(this.getEmailForm.email.value, this.getCodeForm.code.value).subscribe(
        res => {
          this.loading = false;
          if (res && res.message && res.message === 'success' && res.data) {
            this.stepComplete = 2;
            this.showSuccess('You email verified successfully');
            resolve(res);
          } else {
            this.showWarning(res?.message ? res.message : 'Sorry we cannot verify you email.');
            reject();
          }
        },
        error => {
          this.loading = false;
          this.showWarning('Sorry we cannot verify you email.');
          reject(error);
        },
        () => {
          this.loading = false;
        }
      );
    });
  }

  pay() {
    this.subscriptions$.push(this.recaptchaV3Service.execute('importantAction').subscribe({
      next: (token) => {
        if (!token?.length) {
          this.showWarning(
            'Please try again.'
          )
        }
        else {

          if (this.checkoutForm.valid) {
            if (this.isPaymentSucceed) {
              this.registerDo();
              return;
            }

            this.loading = true;
            this.stripeService
              .confirmPayment({
                elements: this.paymentElement.elements,
                confirmParams: {
                  payment_method_data: {
                    billing_details: {
                      name: this.checkoutForm.get('card_name').value,
                      email: this.getEmailForm.email.value,
                    },
                  },
                },
                redirect: 'if_required',
              })
              .subscribe(
                result => {
                  if (result.error) {
                    this.loading = false;
                    this.showWarning(result.error.message);
                  } else {
                    // The payment has been processed!
                    if (result.paymentIntent.status === 'succeeded') {
                      // Register Card to backend
                      this.resTokenId = result.paymentIntent.payment_method;

                      this.isPaymentSucceed = true;
                      this.registerDo();
                    } else {
                      this.loading = false;
                    }
                  }
                },
                error => {
                  this.loading = false;
                }
              );
          } else {
            this.showWarning('Please correctly fill the payment form');
          }

        }
      }
    }))
  }

  async registerDo() {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.api.register(this.getRegisterObj).subscribe(
        res => {
          this.loading = false;
          this.resTokenId = 0;
          if (res.activated) {
            this.stepComplete = 3;
            this.showSuccess('Congratulation! You have completed the registration.');
            // this.stepper.next();
            // $(this.stepperEl).addClass("endView");
            resolve(res);

            setTimeout(() => {
              this.router.navigate(['/auth/login']);
            }, 300);
          } else {
            this.showWarning('Sorry we cannot send your registration, please check data and try again.');

            // this.stepper.to(3);
            this.stepIndex = 3;

            // $(this.stepperEl).removeClass("endView");
            reject();
          }
        },
        error => {
          this.loading = false;
          this.resTokenId = 0;
          this.showWarning('Sorry we cannot send your registration, please check data and try again.');
          reject(error);
        },
        () => {
          this.loading = false;
        }
      );
    });
  }

  togglePasswords(inputField: HTMLInputElement) {
    inputField.type = inputField.type === 'password' ? 'text' : 'password';
  }

  onCountrySelection(evt) {
    this.selectedCountry = evt.value;
    this.profileForm.get('business_address')?.get('country').setValue(this.selectedCountry);
    this.profileForm.get('business_address')?.get('province').setValue('');
    this.getState(this.selectedCountry.code);
  }

  getState(countryCode: string) {
    this.provinces = States[countryCode]?.map(state => ({ name: state }));
  }

  showSuccess = (msg: string) => {
    this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: msg });
  };

  showWarning = (msg: string) => {
    this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Warning', detail: msg });
  };


  cardChange(event: any) {
    this.isCardValid = event.complete ? true : false;
  }

  ngOnDestroy() {
    this.subscriptions$.forEach(subscription=>subscription.unsubscribe())
  }
}
